import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import {Link} from "gatsby"
import {makeKeyFromTitle} from "../../util/Util"
import MenuIconX from "./MenuIconX"
import MenuIconLines from "./MenuIconLines"
import { useLocation } from "@reach/router"
const breakPoint = 768;
const MobileMenu = styled.div`
  display: 
    ${props => {
      if(props.isOpen) return 'flex';
      else return 'none';
    }};
  @media all and (min-width: ${breakPoint+1}px) {
    display: none;
  }
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 2;
  background: #000000;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  &>*{
    display: flex;
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    font-size: 30px;
    margin: 14px 0;
  }
`
const DesktopMenu = styled.div`
  display: flex;
  justify-content: space-between;
  width: 75%;
  @media all and (max-width: ${breakPoint}px) {
    display: none;
  }
  &>*{
    display: flex
  }
`
const MenuItemContainer = styled.div`
  a{
    text-decoration: 
      ${props => {
        if(props.to === useLocation().pathname) return 'line-through';
        else return 'none';
      }};
    pointer-events: 
      ${props => {
        if(props.to === useLocation().pathname) return 'none';
        else return 'auto';
      }};
    color: #FFFFFF;
    &:visited{
      color: #FFFFFF;
    }
  }
  @media all and (min-width: ${breakPoint+1}px) {
    a{
      color: #000000;
      &:visited{
        color: #000000;
      }
    }

  }
`
const Menu = (props)=>{
  const [isOpen, setIsOpen] = useState(false);
  const data = useStaticQuery(graphql`
    query MenuQuery{
      allContentfulMenuItem {
        nodes {
          menuItemLink
          menuItemTitle
          node_locale
        }
      }
    }
  `);
  const menuItems = data.allContentfulMenuItem.nodes.filter((menuItem)=>{
    if (menuItem.node_locale === props.locale) return menuItem;
    return null;
  });
  const menuItemComponents = menuItems.map((menuItem)=>{
    return(
      <MenuItemContainer to={menuItem.menuItemLink} key={makeKeyFromTitle(menuItem.menuItemTitle)}>
        <Link to={menuItem.menuItemLink}>{menuItem.menuItemTitle}</Link>
      </MenuItemContainer>
    )
  })
  const toggleMenu = () => {
    if(isOpen) setIsOpen(false);
    else setIsOpen(true);
  }
  const DURATION = 1000;
  let renderMenuIcon = (toggleMenu) => {
    if(isOpen) return (<MenuIconX toggleMenu={toggleMenu} duration={DURATION}/>);
    else return (<MenuIconLines toggleMenu={toggleMenu} duration={DURATION}/>);
  }
  return(
    <React.Fragment>
      <MobileMenu numItems={menuItemComponents.length} isOpen={isOpen}>
        {menuItemComponents}
      </MobileMenu>
      {renderMenuIcon(toggleMenu)}
      <DesktopMenu>
        {menuItemComponents}
      </DesktopMenu>
    </React.Fragment>
  )
}
export default Menu;