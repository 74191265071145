import React from "react"
import {Link} from "gatsby"
import logo from  "./logo.svg"
import styled from "styled-components"
import Menu from "./header/Menu"
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Comfortaa';
  height: 50px;
  width: 100%;
  background-color: white;
  &>.logo{
    justify-content: flex-start;
  }
`
const Logo = styled.img`    
  height: 36px;
  `
const getCorrectHome = (locale)=>{
  if(locale === "es") return "/es/"
  else return "/"
}
const Header = (props) => {
  return(
    <HeaderContainer>
      <Link to={getCorrectHome(props.locale)}>
        <Logo className={"logo"} src={logo} alt="eCommerce logo" />
      </Link>
      <Menu locale={props.locale}/>
    </HeaderContainer>
  );
}
export default Header;