import React, { useEffect } from "react"
import styled from "styled-components"
import anime from "animejs";
const breakPoint = 768;
const MenuIconContainer = styled.div`
    position: fixed;
    right: 20px;
    z-index: 2;
    width: 24px; 
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media all and (min-width: ${breakPoint+1}px) {
      display: none;
    }
  } 
`
const Line1 = styled.div`
  position: absolute;
  height: 3px;
  border-radius: 2px;
  background-color: #FFFFFF;
  width: 24px;
  top:11;
`
const Line4 = styled(Line1)`
  top: 11px;
`
const MenuIconX = (props) => {
  useEffect(()=>{
    anime({
      targets: ['.line1'],
      rotate: 45,
      translateY: 1,
      duration: props.duration,
      autoplay: true,
    });
    anime({
      targets: ['.line4'],
      rotate: -45,
      translateY: -1,
      duration: props.duration,
      autoplay: true,
    });
  });

  let toggleMenu = ()=>{
    props.toggleMenu();
  }

  return (
    <MenuIconContainer onClick={toggleMenu} className="menuContainer">
      <Line1 className="line1"></Line1>
      <Line4 className="line4"></Line4>
    </MenuIconContainer>
  );
}
export default MenuIconX;