export const makeKeyFromTitle = (title)=>{
  if(title==null){
    console.log('Error: randomKey Generated');
    return 'randomKey'+ Math.floor((Math.random() * 10) + 1);
  }
  let key = title.replace(/\W/g, '');
  key =
    key.substring(0, 3) +
    key.substring(key.length/2 - 2, key.length/2 + 2) +
    key.substring(key.length - 3, key.length);
  key = key.toLowerCase();
  return key;
}
export default makeKeyFromTitle;

