import React, { useEffect } from "react"
import styled from "styled-components"
import anime from "animejs";
const breakPoint = 768;
const MenuIconContainer = styled.div`
  position: relative;
  width: 24px; 
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media all and (min-width: ${breakPoint+1}px) {
    display: none;
  }  
`
const Line1 = styled.div`
  position: absolute;
  top: 9px;
  height: 3px;
  border-radius: 2px;
  background-color: #000000;
  width: 100%;
`
const Line2 = styled(Line1)`
  width: 20%;
  top: 9px;
  left: 0;
  background-color: #777777;
  `
const Line3 = styled(Line1)`
  width: 60%;
  top: 9px;
  right: 0;
  `
const Line4 = styled(Line1)`
  top: 9px;
`
const MenuIconLines = (props) => {
  useEffect(()=>{
    anime({
      targets: ['.line1'],
      duration: props.duration,
      autoplay: true,
      translateY: -7.5,
      rotate: 0
    })
    anime({
      targets: ['.line4'],
      duration: props.duration,
      autoplay: true,
      translateY: 7.5,
      rotate: 0,
    })
  })
  let toggleMenu = ()=>{
    props.toggleMenu();
  }
  return (
    <MenuIconContainer onClick={toggleMenu} className="menuContainer">
      <Line1 className="line1"></Line1>
      <Line2 className="line2"></Line2>
      <Line3 className="line3"></Line3>
      <Line4 className="line4"></Line4>
    </MenuIconContainer>
  );
}
export default MenuIconLines;